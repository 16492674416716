import React from "react";
import { graphql } from "gatsby";
import { styled } from "utils/emotion";
import GalleryTile from "components/GalleryTile";

const TrainersSectionStyled = styled("section")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  backgroundColor: "#120f0b",
  position: "relative",
  zIndex: 1,
});

const GalleryTileStyled = styled(GalleryTile)({
  width: "33.333%",
  flexGrow: 1,
});

export default function Trainers({ pageBuilder }) {
  const { trainer } = pageBuilder;

  return (
    <TrainersSectionStyled>
      {trainer.map(
        ({ trainerTeaser, trainerName, trainerImage, trainerLink }, index) => (
          <GalleryTileStyled
            key={"trainer" + index}
            text={trainerTeaser}
            headline={trainerName}
            image={trainerImage}
            link={trainerLink}
          />
        )
      )}
    </TrainersSectionStyled>
  );
}

export const query = graphql`
  fragment TrainersQuery on Craft_PageBuilderTrainers {
    trainer {
      trainerName
      trainerTeaser
      trainerLink {
        customText
        url
        entry {
          fullUri
        }
      }
      trainerImage {
        ...CraftImageFluid
      }
    }
  }
`;
